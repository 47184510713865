import { Component } from '@angular/core';

import { StatusBar } from '@capacitor/status-bar';
import { Device } from '@capacitor/device';
import { EventsService } from './services/events.service';

@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss'],
})
export class AppComponent {

  shouldDisabledSplitPane: boolean = false;
  showProgressBar: boolean = false;

  constructor(private eventsService: EventsService) {
    Device.getInfo().then((info) => {
      if (info.platform === "web") return;
      StatusBar.setBackgroundColor({ color: "#272728" });
    });

    this.eventsService.operationInProgress$.subscribe((operationInProgress) => {
      this.showProgressBar = operationInProgress;
    });
  }
}
