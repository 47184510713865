import { Injectable } from '@angular/core';
import { collection, CollectionReference, Firestore, getDocs, query, Query, where, addDoc, doc, setDoc, DocumentReference, deleteDoc } from '@angular/fire/firestore';
import { UserRef } from '../model/docs-ref.model';
import { AppUser } from '../model/user.model';

@Injectable({
  providedIn: 'root'
})
export class UserService {

  private collection: CollectionReference<AppUser>;

  constructor(private firestore: Firestore) {
    this.collection = collection(this.firestore, 'users');
  }

  getRef(userId: string): UserRef {
    return doc(this.collection, userId);
  }

  async getUserByAuthUID(uid: string): Promise<AppUser> {
    const q: Query<AppUser> = query(this.collection, where('uid', '==', uid));
    const snap = await getDocs(q);
    let match = snap.docs[0];
    return match ? { ...match.data(), id: match.id } : null;
  }

  update(userPartial: AppUser, id: string): Promise<void> {
    if (userPartial.id) delete userPartial.id;
    const docRef = doc(this.collection, id);
    return setDoc(docRef, userPartial, { merge: true });
  }

  insert(userPartial: AppUser): Promise<DocumentReference<AppUser>> {
    return addDoc(this.collection, userPartial);
  }

  delete(docRef: UserRef): Promise<void> {
    return deleteDoc(docRef);
  }

}
