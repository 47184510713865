import { Injectable } from '@angular/core';
import { CanActivate, CanLoad, Router, UrlTree } from '@angular/router';
import { Observable } from 'rxjs';
import { AuthService } from '../services/auth.service';
import { Storage } from '@capacitor/storage';
import { AUTH_STORAGE_KEY } from '../../environments/environment';
import { User } from '@angular/fire/auth';
import { AppUser } from '../model/user.model';
import { UserService } from '../services/user.service';

@Injectable({
  providedIn: 'root'
})
export class AuthGuard implements CanActivate {

  constructor(private router: Router, private authService: AuthService, private userService: UserService) { }

  canActivate(): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    return this.checkIsSignedIn();
  }

  async checkIsSignedIn() {
    const user: User = JSON.parse((await Storage.get({ key: AUTH_STORAGE_KEY })).value);
    if (!user) return this.goLogin();
    if (!(await this.userService.getUserByAuthUID(user.uid))) return this.goLogin();
    return true;
  }

  goLogin() {
    this.router.navigate(['login'], { replaceUrl: true });
    return false;
  }
}
