// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,

  firebase: {
    apiKey: "AIzaSyCCiWmAP8z10HclDeEz2KM2KwTTHzpwxm0",
    authDomain: "liberdade-planejavel-hml.firebaseapp.com",
    projectId: "liberdade-planejavel-hml",
    storageBucket: "liberdade-planejavel-hml.appspot.com",
    messagingSenderId: "853585651210",
    appId: "1:853585651210:web:7910f69d84e1514e5fa1f3",
    measurementId: "G-RHSL64HJ88"
  }
};

export const AUTH_STORAGE_KEY = 'AUTH_USER';

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
