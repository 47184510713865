import { Injectable } from '@angular/core';
import { Device } from '@capacitor/device';
import { Keyboard } from '@capacitor/keyboard';
import { IonButton, IonInput, IonTextarea } from '@ionic/angular';
import { BehaviorSubject, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class EventsService {

  private operationInProgressBS: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  operationInProgress$: Observable<boolean> = this.operationInProgressBS.asObservable();

  private updateChartsBS: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  updateCharts$: Observable<boolean> = this.updateChartsBS.asObservable();

  constructor() { }

  /**
   * Check if the key pressed is `Enter` and, if so, perform the `action` on `nextField`
   * @param ev KeyboardEvent containing the pressed key
   * @param nextField The IonInput/IonTextarea/IonButton to receive the focus/click
   * @param action which action to perform, give `nextField` focus or trigger a click event
   */
  checkEnterKeyInput(ev: KeyboardEvent, nextField: IonInput | IonTextarea | IonButton, action: 'click' | 'focus') {
    if (ev.key !== 'Enter' || (nextField instanceof IonButton && action === 'focus')) return;
    if (action === 'click') {
      Device.getInfo().then((info) => {
        if (info.platform !== 'web') Keyboard.hide();
      });
      return nextField['el']?.click();
    }
    if (action === 'focus' && !(nextField instanceof IonButton)) return nextField.setFocus();
  }


  public set operationProgress(v: boolean) { this.operationInProgressBS.next(v); }

  public set updateCharts(v: boolean) { this.updateChartsBS.next(v); }

}
