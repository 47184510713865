import { Injectable } from '@angular/core';
import {
  Auth, FacebookAuthProvider, GoogleAuthProvider, onAuthStateChanged, signInWithPopup,
  signOut, User, authState, signInWithEmailAndPassword, UserCredential, sendPasswordResetEmail, fetchSignInMethodsForEmail,
  createUserWithEmailAndPassword,
} from '@angular/fire/auth';
import { Router } from '@angular/router';

import { Storage } from '@capacitor/storage';
import { BehaviorSubject, Observable } from 'rxjs';
import { AUTH_STORAGE_KEY } from 'src/environments/environment';
import { AppUser } from '../model/user.model';
import { UserService } from './user.service';

@Injectable({
  providedIn: 'root'
})
export class AuthService {

  private user: User;

  private appUserBS: BehaviorSubject<AppUser> = new BehaviorSubject<AppUser>(null);
  appUserState$: Observable<AppUser> = this.appUserBS.asObservable();

  constructor(private auth: Auth, private userService: UserService) {
    this.auth.useDeviceLanguage();
    onAuthStateChanged(this.auth, (user) => {
      if (user) {
        this.user = user;

        Storage.set({ key: AUTH_STORAGE_KEY, value: JSON.stringify(user) }).then(() => {

          this.userService.getUserByAuthUID(user.uid).then((appUser) => {
            this.appUserBS.next(appUser);
          });

          // this.appUserBS.asObservable().subscribe(appUser => {
          //   this.appUser = appUser;
          // });
        }).catch((err) => { console.log(err); });
      } else {
        Storage.set({ key: AUTH_STORAGE_KEY, value: null }).catch((err) => { console.log(err); });
      }
    });
  }

  getState() {
    return authState(this.auth);
  }

  signInGoogle() {
    return signInWithPopup(this.auth, new GoogleAuthProvider());
  }

  signInFacebook() {
    return signInWithPopup(this.auth, new FacebookAuthProvider());
  }

  signUpEmailPassword(email: string, password: string): Promise<UserCredential> {
    return createUserWithEmailAndPassword(this.auth, email, password);
  }

  signInEmailPassword(email: string, password: string): Promise<UserCredential> {
    return signInWithEmailAndPassword(this.auth, email, password);
  }

  sendResetEmail(email: string): Promise<void> {
    return sendPasswordResetEmail(this.auth, email);
  }

  getSignInMethodsEmail(email: string): Promise<Array<string>> {
    return fetchSignInMethodsForEmail(this.auth, email);
  }

  signOutAuth(): Promise<void> {
    return signOut(this.auth);
  }

  get currentUser(): User { return this.user; }
  get isSignedIn(): Promise<boolean> { return Storage.get({ key: AUTH_STORAGE_KEY }).then((res) => { return JSON.parse(res?.value) !== undefined; }); }

}
